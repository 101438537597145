/**
 * 引入深拷贝函数
 */
import { deepClone } from './deepClone'

/**
 * 树转数组扁平化结构
 * 深度优先遍历  递归
 * @param {Array, Object} node 树
 * @param {String} child 子级标识
 * @param {String} parentId 关联父级ID
 */
export function deepTreeToArr(node, params) {
  let{ key = 'id', child = 'children', parentId = 'parentId' } = params
  console.log(key)
  if (!(node instanceof Object)) {
    console.error('传入的值必需为Array/Object')
    return
  }
  const result = []
  const loop = (data, index, parent) => {
    console.log(parent)
    let temp = deepClone(data)
    delete temp[child]
    delete temp['component']
    let obj = {
      ...temp,
      [key]: temp[key] || index.toString(),
      name: temp.name,
      [parentId]: parent,
      component: ''
    }
    result.push(obj)
    let children = data[child]
    if (children) {
      for (let i = 0; i < children.length; i++) {
        loop(children[i], obj[key] + `${i}`, obj[key])
      }
    }
  }
  node.forEach((item, index) => {
    loop(item, index)
  })
  return result
}
