<template>
  <div class="test">
    {{arr}}
  </div>
</template>

<script>
import { constantRoutes } from '@/router'
import { deepTreeToArr } from '@/utils/treeToArr.js'

export default {
  mounted() {
    this.arr = deepTreeToArr(constantRoutes, {
      key: 'id',
      child: 'children',
      parentId: 'parentId'
    })
    // this.arr = constantRoutes
  },
  data() {
    return {
      arr: []
    }
  }
}
</script>

<style lang="scss" scoped>
.test {

}
</style>
